<template>
  <section class="desgin">

    <Loader :loading="showLoader" />
    <div class="row flex-grow">
      <div class="row flex-grow">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
             
              <form @submit.prevent="editContactData" class="forms-sample">
                <div class="row">
                  <div class="col-md-12">
                    <div role="group" class="form-group">
                      <label class="d-block">Title</label>
                      <div>
                        <input type="text" placeholder="Title" v-model="contactData.title" class="form-control">
                      </div>
                    </div>
  
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Banner Image</label>
                      <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                        @vdropzone-success="bannerUpload">
                        <div class="dropzone-custom-content">
                          <div class="image-size-dropzone">
                            <div>
                              <img :src="`${imageURL}/${contactData.bannerSmall}`" class="show-img img-fluid" />
                            </div>
                          </div>
                          <div class="subtitle p-2">
                            <span>Change</span>
                            <span style="color: #eb2027"> Image</span>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div role="group" class="form-group">
                      <label class="d-block">Banner Image</label>
                      <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                        @vdropzone-success="bannerBigUpload">
                        <div class="dropzone-custom-content">
                          <div class="image-size-dropzone">
                            <div>
                              <img :src="`${imageURL}/${contactData.banner}`" class="show-img img-fluid" />
                            </div>
                          </div>
                          <div class="subtitle p-2">
                            <span>Change</span>
                            <span style="color: #eb2027"> Image</span>
                          </div>
                        </div>
                      </vue-dropzone>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div role="group" class="form-group">
                      <label class="d-block">Google Map  </label>
                      <div>
                        <input type="text" placeholder="Google Map" v-model="contactData.mapLink" class="form-control">
                      </div>
                    </div>
                  </div>
                </div>
                <button type="submit" class="btn mr-2 btn-primary">Submit</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'contactData',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      showLoader: false,
      imageURL,
      contactData: "",
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },
    };
  },
  mounted() {
    this.contactDataById();
  },
  methods: {

    async contactDataById() {
      this.showLoader = true;
      const { data } = await sliderApi.getContactDataById(`1`);
      this.contactData = data.data.data;
      this.showLoader = false;
    },

    bannerBigUpload(file, response) {
      this.contactData.banner = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    bannerUpload(file, response) {
      this.contactData.bannerSmall = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async editContactData() {
      this.showLoader = true;
      const data = await sliderApi.updateContactData(this.contactData._id, {
        ...this.contactData,
      });
      this.showLoader = true;
      this.contactDataById();
      if (data.status == 200) {
        
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
    },

  }
}
</script>
